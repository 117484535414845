import * as React from 'react';
import {
  TextPriority1,
  Spacing,
  TextSmall,
  TextSub2,
} from 'ui/components/atoms/typography/typography';
import { Container } from 'ui/components/organisms/container/container';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import { FeatureIcon, FeatureIcons } from 'ui/components/molecules/feature/feature_icon';
import styles from './banner_home.module.css';
import { ScrollButton } from 'ui/components/atoms/scroll_button/scroll_button';
import { Picture } from 'ui/components/atoms/picture/picture';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { scrollToElement } from 'util/scrollTo';
import { Vimeo } from '../vimeo/vimeo';

export type BannerHomeProps = {
  preheading: string;
  heading: string;
  subheading: string;
  asset: WPImageFluid | string;
  scrollRef: React.RefObject<HTMLDivElement>;
  features: {
    heading: string;
    items:
      | null
      | {
          heading: string;
          icon: FeatureIcons;
          link: {
            uri: string;
          };
        }[];
  };
};

const createViewTreatmentsClick = (ref: React.RefObject<HTMLDivElement>) => () => {
  if (ref.current != null) {
    scrollToElement(ref.current);
  }
};

export const BannerHome = ({
  heading,
  subheading,
  asset,
  preheading,
  features,
  scrollRef,
}: BannerHomeProps) => {
  const bannerCTAClick = createViewTreatmentsClick(scrollRef);
  return (
    <React.Fragment>
      <Container>
        <div className={styles.textContent}>
          <TextSmall tagName="div" className={styles.preheading} bottomSpacing={Spacing.SPACING_16}>
            {preheading}
          </TextSmall>
          <TextPriority1 bottomSpacing={Spacing.SPACING_16}>{heading}</TextPriority1>
          <ScrollButton
            subheading={subheading}
            className={styles.subheading}
            onClick={bannerCTAClick}
          />
          <div className={styles.divider} />
        </div>
      </Container>
      <div className={styles.image}>
        <Container>
          <Row>
            <Col sm={12}>
              {typeof asset === 'string' ? (
                <Vimeo id={asset} />
              ) : (
                <Picture
                  type="fluid"
                  fluid={asset.localFile.childImageSharp.fluid}
                  placeholder="285x190"
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={styles.featuresContainer}>
        <TextSub2 tagName="div" bottomSpacing={Spacing.SPACING_16}>
          {features.heading}
        </TextSub2>
        {features.items != null ? (
          <div className={styles.scroller}>
            <div className={styles.features}>
              {features.items.map((x, i) => (
                <FeatureIcon
                  className={styles.featuresItem}
                  textContentClassName={styles.featuresItemTextContent}
                  background="pale"
                  key={i}
                  icon={x.icon}
                  href={x.link.uri}
                  heading={x.heading}
                />
              ))}
            </div>
          </div>
        ) : null}
      </Container>
    </React.Fragment>
  );
};
