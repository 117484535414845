import React, { useRef } from 'react';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import { PageTemplateProps } from 'shared/types/pages';
import { Container } from 'ui/components/organisms/container/container';
import { BannerHome } from 'ui/components/molecules/banner/banner_home';
import { Instagram } from 'ui/components/molecules/instagram/instagram';
import { Section } from 'ui/components/molecules/section/section';
import { TextPriority3, TextSub2, Spacing } from 'ui/components/atoms/typography/typography';
import { CardLink } from 'ui/components/molecules/card/card';
import { HomePageProps } from 'templates/home';
import { getFeaturedImage } from 'util/getFeaturedImage';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { JsonLD } from 'ui/components/molecules/json_ld/json_ld';

export const Home = ({ data }: PageTemplateProps<HomePageProps>) => {
  const acfSharedPage = data.wpPage.acfSharedPage;
  const { breadcrumbs } = data.wpPage.seo;
  const { banner, grid } = data.wpPage.acfTemplateHome.home;
  const { handle } = data.wpGlobal.globalOptions.acfGlobalOptions.instagram;
  const ref = useRef<HTMLDivElement>(null);
  const asset = banner.type === 'image' ? (banner.image as WPImageFluid) : (banner.video as string);
  return (
    <React.Fragment>
      <JsonLD title={data.wpPage.title} acfSharedPage={acfSharedPage} breadcrumbs={breadcrumbs} />
      <BannerHome
        heading={banner.heading}
        subheading={banner.subheading}
        preheading={banner.preheading}
        asset={asset}
        features={banner.features}
        scrollRef={ref}
      />
      <div ref={ref}></div>
      <Section backgroundColor="grey">
        <Container>
          <Row center="sm">
            <Col sm={12}>
              <TextSub2 tagName="div" bottomSpacing={Spacing.SPACING_8}>
                {grid.subheading}
              </TextSub2>
              <TextPriority3 bottomSpacing={Spacing.SPACING_40}>{grid.heading}</TextPriority3>
            </Col>
          </Row>
          {grid.items != null ? (
            <Row>
              {grid.items
                .filter(x => x.link != null)
                .map((card, key) => (
                  <Col key={key} sm={6} md={4} lg={3}>
                    <CardLink
                      heading={card.link.title}
                      image={getFeaturedImage(card?.link?.featuredImage?.node)}
                      description={card.link.excerpt}
                      uri={card.link.uri}
                    />
                  </Col>
                ))}
            </Row>
          ) : null}
        </Container>
      </Section>
      <Instagram handle={handle} posts={data.allInstaNode || []} />
    </React.Fragment>
  );
};
