import * as React from 'react';
import classNames from 'classnames';
import { TextPriority6, TextRegularAlt, Spacing } from 'ui/components/atoms/typography/typography';
import { WPImageFluid } from 'shared/types/wp_localImage';
import styles from './card.module.css';
import { Link } from 'gatsby';
import { HtmlSanitizer } from 'util/html';
import { Picture } from 'ui/components/atoms/picture/picture';

type CardProps = {
  heading: string;
  description: string;
  subheading?: string;
  image?: WPImageFluid;
};

const renderCard = ({ heading, description, subheading, image }: CardProps) => {
  return (
    <React.Fragment>
      <div className={styles.image}>
        <Picture
          type="fluid"
          fluid={image?.localFile.childImageSharp.fluid}
          placeholder="285x190"
        />
      </div>
      <TextPriority6 bottomSpacing={Spacing.SPACING_16}>{heading}</TextPriority6>
      <TextRegularAlt tagName="div">
        <HtmlSanitizer html={description} />
      </TextRegularAlt>
      {subheading && <TextRegularAlt className={styles.subheading}>{subheading}</TextRegularAlt>}
    </React.Fragment>
  );
};

export const CardLink = ({ uri, ...props }: CardProps & { uri: string }) => {
  return (
    <Link to={uri} className={classNames(styles.card, styles.link)}>
      {renderCard(props)}
    </Link>
  );
};

export const Card = (props: CardProps) => {
  return <div className={styles.card}>{renderCard(props)}</div>;
};
