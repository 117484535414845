import { WpImageFixed, WPImageFluid } from 'shared/types/wp_localImage';

type FluidFeaturedImage = WPImageFluid | null | undefined;

export const getFeaturedImage = (image: FluidFeaturedImage) => {
  return image ? image : undefined;
};

type FixedFeaturedImage = WpImageFixed | null | undefined;

export const getFeaturedFixedImage = (image: FixedFeaturedImage) => {
  return image ? image : undefined;
};
