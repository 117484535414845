import { graphql } from 'gatsby';
import { WPPageLink } from 'shared/types/wp_link';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { Home } from 'ui/components/pages/home/home';

export const query = graphql`
  query HomePageQuery($id: String!) {
    allInstaNode {
      ...InstagramFeed
    }
    wpPage(id: { eq: $id }) {
      ...PageContent
      acfTemplateHome {
        home {
          banner {
            preheading
            heading
            subheading
            type
            video
            image {
              localFile {
                ...FluidImageFull
              }
            }
            features {
              heading
              items {
                heading
                icon
                link {
                  ... on WpPage {
                    uri
                  }
                }
              }
            }
          }
          grid {
            heading
            subheading
            items {
              link {
                ...PageLink
              }
            }
          }
        }
      }
    }
    wpGlobal: wp {
      ...WpGlobal
    }
  }
`;

// TODO: Generate these via Apollo Codegen
type HomePageIcons =
  | 'face-neck'
  | 'body'
  | 'skin'
  | 'injectables'
  | 'womens-intimate'
  | 'mens-calibre';

export type HomePageProps = {
  acfTemplateHome: {
    home: {
      banner: {
        preheading: string;
        heading: string;
        subheading: string;
        image: null | WPImageFluid;
        type: 'video' | 'image';
        video: null | string;
        features: {
          heading: string;
          items:
            | null
            | {
                heading: string;
                icon: HomePageIcons;
                link: WPPageLink;
              }[];
        };
      };
      grid: {
        heading: string;
        subheading: string;
        items:
          | null
          | {
              link: WPPageLink;
            }[];
      };
    };
  };
};

export default Home;
