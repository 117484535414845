import * as React from 'react';
import classNames from 'classnames';
import styles from './section.module.css';

export type BackgroundColor = 'white' | 'navy' | 'pale' | 'pale40' | 'grey';

type SectionProps = {
  children: any;
  backgroundColor: BackgroundColor;
  className?: string;
};

export const Section = ({ children, backgroundColor, className }: SectionProps) => {
  return (
    <section
      className={classNames(
        styles.section,
        {
          [styles.bgNavy]: backgroundColor === 'navy',
          [styles.bgPale]: backgroundColor === 'pale',
          [styles.bgPale40]: backgroundColor === 'pale40',
          [styles.bgGrey]: backgroundColor === 'grey',
        },
        className,
      )}>
      {children}
    </section>
  );
};
