import * as React from 'react';
import classNames from 'classnames';
import { TextSub1 } from 'ui/components/atoms/typography/typography';
import Arrow from 'ui/components/atoms/icons/arrow.inline.svg';
import styles from './scroll_button.module.css';

type ScrollButtonProps = {
  subheading: string;
  className?: string;
  onClick?: () => void;
};

/**
 * A re-usable JSX Element to scroll to a target HTMLElement
 */
export const ScrollButton = ({ subheading, className, onClick }: ScrollButtonProps) => (
  <div className={classNames(styles.button, className)} onClick={onClick}>
    <TextSub1 tagName="div" className={styles.subheading}>
      {subheading}
    </TextSub1>
    <div className={styles.icon}>
      <Arrow />
    </div>
  </div>
);
