import * as React from 'react';
import classNames from 'classnames';
import { Spacing, TextPriority6 } from 'ui/components/atoms/typography/typography';
import { Link } from 'gatsby';
import styles from './feature.module.css';
import { WYSIWYG } from '../wysiwyg/wysiwyg';

export type FeatureProps = {
  className?: string;
  textContentClassName?: string;
  heading: string;
  description?: string;
  href: string;
  background?: 'pale';
};

export const Feature = ({
  background,
  heading,
  description,
  className,
  textContentClassName,
  href,
  children,
}: FeatureProps & { children?: any }) => {
  const hasDescription = (description != null && description.length > 0) ?? false;
  return (
    <Link to={href} className={classNames(styles.feature, className)}>
      <div className={classNames(styles.thumbnail, { [styles.bgPale]: background === 'pale' })}>
        <div
          className={classNames(styles.pictureContainer, {
            [styles.bgPale]: background === 'pale',
          })}>
          {children}
        </div>
      </div>
      <div
        className={classNames(styles.textContent, textContentClassName, {
          [styles.textCentered]: !hasDescription,
        })}>
        <TextPriority6 bottomSpacing={hasDescription ? Spacing.SPACING_8 : Spacing.NONE}>
          {heading}
        </TextPriority6>
        {description && (
          <div className={styles.description}>
            <WYSIWYG html={description} />
          </div>
        )}
      </div>
    </Link>
  );
};
