import * as React from 'react';
import { TextSmall } from 'ui/components/atoms/typography/typography';
import styles from './vimeo.module.css';

/**
 *
 * @param params Object of key-value to represent query string params
 *
 * Convert an object to a query string
 */
const stringifyParams = (params: { [key: string]: any }) =>
  Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');

type VimeoProps = {
  id: string;
  caption?: string;
};

export const Vimeo = ({ id, caption }: VimeoProps) => {
  const iframeParams = {
    controls: true,
    title: false,
    portrait: false,
  };
  const stringIframeParams = stringifyParams(iframeParams);
  return (
    <div className={styles.container}>
      <div className={styles.player}>
        <iframe
          src={`https://player.vimeo.com/video/${id}${
            stringIframeParams ? `?embedparameter=${stringIframeParams}` : ''
          }`}
          frameBorder="0"
          className={styles.iframe}
          allowFullScreen
        />
      </div>
      {caption && (
        <TextSmall tagName="div" className={styles.caption}>
          {caption}
        </TextSmall>
      )}
    </div>
  );
};
