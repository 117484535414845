import * as React from 'react';
import { Container } from 'ui/components/organisms/container/container';
import { TextSub2, TextPriority3 } from 'ui/components/atoms/typography/typography';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import { Divider } from 'ui/components/atoms/divider/divider';
import { capitalize } from 'util/capitalize';
import styles from './instagram.module.css';
import {
  InstagramFeed,
  InstagramFeed_edges,
  InstagramFeed_edges_node,
} from 'queries/gql_types/InstagramFeed';

const splitRemaining = (remaining: InstagramFeed_edges[]) => {
  return [
    [...remaining.slice(0, Math.ceil(remaining.length / 2))],
    [...remaining.slice(Math.ceil(remaining.length / 2))],
  ];
};

const InstagramPost = ({ permalink, localFile }: InstagramFeed_edges_node) => {
  if (
    localFile == null ||
    localFile.childImageSharp == null ||
    localFile.childImageSharp.fluid == null ||
    localFile.childImageSharp.fluid.srcWebp == null ||
    localFile.childImageSharp.fluid.srcSetWebp == null ||
    permalink == null
  ) {
    // eslint-disable-next-line
    console.error('INSTAGRAM POST ERROR FOUND: Missing property');
    return null;
  }
  return (
    <a className={styles.tile} target="_blank" rel="noreferrer" href={permalink}>
      <img className={styles.image} src={localFile.childImageSharp.fluid.src} loading="lazy" />
    </a>
  );
};

function InstagramComponent({ posts, handle }: { posts: InstagramFeed; handle: string }) {
  if (!posts.edges.length) {
    return null;
  }
  const instagramResults = posts.edges
    .sort((x, y) => new Date(x.node.timestamp).getTime() - new Date(y.node.timestamp).getTime())
    .reverse();
  const [featured, ...remaining] = instagramResults;
  return (
    <div className={styles.instagram}>
      <Divider />
      <Container>
        <Row center="sm">
          <Col>
            <TextSub2 className={styles.subheading} tagName="div">
              Follow Us
            </TextSub2>
            <TextPriority3 className={styles.heading}>@{capitalize(handle)}</TextPriority3>
          </Col>
        </Row>
        <div className={styles.scroller}>
          <div className={styles.tiles}>
            <div className={styles.featured}>
              <InstagramPost {...featured.node} />
            </div>
            <div className={styles.remaining}>
              {splitRemaining(remaining).map((row, i) => (
                <div key={i} className={styles.remainingRow}>
                  {row.map((tile, k) => (
                    <div className={styles.remainingItem} key={k}>
                      <InstagramPost {...tile.node} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export const Instagram = React.memo(InstagramComponent);
