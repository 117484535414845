import * as React from 'react';
import { Feature, FeatureProps } from './feature';
import IconBody from './body.inline.svg';
import IconFaceNeck from './face-neck.inline.svg';
import IconInjectables from './injectables.inline.svg';
import IconMelsCalibre from './mens-calibre.inline.svg';
import IconSkin from './skin.inline.svg';
import IconWomensIntimate from './womens-intimate.inline.svg';

export type FeatureIcons =
  | 'face-neck'
  | 'body'
  | 'skin'
  | 'injectables'
  | 'womens-intimate'
  | 'mens-calibre';

export const IconMap: {
  [key: string]: string;
} = {
  'face-neck': IconFaceNeck,
  body: IconBody,
  skin: IconSkin,
  injectables: IconInjectables,
  'womens-intimate': IconWomensIntimate,
  'mens-calibre': IconMelsCalibre,
};

export const FeatureIcon = ({ icon, ...props }: FeatureProps & { icon: FeatureIcons }) => {
  const Icon = IconMap[icon];
  return (
    <Feature {...props}>
      <Icon />
    </Feature>
  );
};
